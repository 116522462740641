(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.persons.controller:ImportAthletesDialogControllerCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.persons')
  .controller('ImportAthletesDialogControllerCtrl', ImportAthletesDialogControllerCtrl);

  function ImportAthletesDialogControllerCtrl($scope, $mdDialog, athletesToImport, $filter) {
    $scope.importList = [];
    $scope.notFoundMessage = $filter('translate')('PEOPLE.NO_ATHLETES_TO_IMPORT');
    angular.forEach(athletesToImport,function (athlete) {
      athlete.bornDate = moment(athlete.bornDate).format('DD/MM/YYYY')
    });
    $scope.athletesToImport = athletesToImport;
    $scope.listToImport = function (item, list) {
      var idx = list.indexOf(item.id);
      if (idx > -1) {
        list.splice(idx, 1);
      }
      else {
        list.push(item.id);
      }
    };

    $scope.cancel = function () {
      $mdDialog.cancel()
    };

    $scope.importPeople = function(){
      $mdDialog.hide($scope.importList)
    }
  }
}());
